import './style.css'
import * as THREE from 'three'
import { OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import gsap from 'gsap'
import * as dat from 'dat.gui'
import {metalness} from "three/examples/jsm/renderers/nodes/ShaderNode";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
//const gui = new dat.GUI()
//Canvas
const canvas = document.querySelector('canvas.webgl')

const scene = new THREE.Scene()

/*
====================================== START FOG ========================================================
 */
const fog = new THREE.Fog('#213776', 0.0001, 20)
scene.fog = fog
/*
====================================== LOADERS ==========================================================
 */
const loadingManager = new THREE.LoadingManager()
loadingManager.onStart = () =>
{
    console.log('loadingManager: loading started')
}
loadingManager.onLoad = () =>
{
    console.log('loadingManager: loading finished')
}
loadingManager.onProgress = () =>
{
    console.log('loadingManager: loading progressing')
}
loadingManager.onError = () =>
{
    console.log('loadingManager: loading error')
}
/*
====================================== CREATE VIDEO TEXTURES ========================================================
 */

const video01 = document.getElementById( 'video01' );
video01.load()
video01.play();
video01.needsUpdate = true;

const videoTexture = new THREE.VideoTexture( video01 );
videoTexture.minFilter = THREE.NearestFilter;
videoTexture.magFilter = THREE.LinearFilter;


const videoMat = new THREE.MeshBasicMaterial( {
    map: videoTexture,
    side : THREE.FrontSide,

});


const screen = new THREE.PlaneBufferGeometry(1, 1);
const videoScreen = new THREE.Mesh(screen, videoMat);
videoScreen.position.set(0.0, 1.0, -1.0)
scene.add(videoScreen);

/*
====================================== START GEO ===========================================================
 */






/*
====================================== LOAD GLTF ===========================================================
 */




const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

let mixer = null

gltfLoader.load(
    '/geo/aod/AOD_LabSpace/AOD_LabSpace_v001.gltf',
    (gltf) =>
    {
        gltf.scene.scale.set(1.0, 1.0, 1.0)
        gltf.scene.traverse( function (node){
            if (node.isMesh) {node.recieveShadow = true}
            if (node.isMesh) {node.castShadow = true}

        })

        scene.add(gltf.scene)
    }
)

gltfLoader.load(
    '/geo/aod/AOD-LOGO.gltf',
    (gltf) =>
    {
        gltf.scene.scale.set(0.01, 0.01, 0.01)
        gltf.scene.position.set(-5.0, 0.0, -3.50)
        gltf.scene.traverse( function (node){
            if (node.isMesh) {node.castShadow = true}
            if (node.isMesh) node.material = videoMat
        })
        scene.add(gltf.scene)
    }
)

gltfLoader.load(
    '/geo/avatars/guyAnim_005.gltf',
    (gltf) =>
    {
        gltf.scene.scale.set(100.0, 100.0, 100.0)
        gltf.scene.position.set(-1.0, 0.0, 1.00)
        gltf.scene.traverse( function (node){
            if (node.isMesh) {node.castShadow = true}
        })
        scene.add(gltf.scene)

        Animation
        mixer = new THREE.AnimationMixer(gltf.scene)
        const action = mixer.clipAction(gltf.animations[0])
        action.play()
    }
)

for (let i = 0; i<20; i++)
gltfLoader.load(
    '/geo/rocks/rock_001.gltf',
    (gltf) =>
    {
        const angle = Math.random() * Math.PI * 2
        const radius = 2.5 + Math.random() * .75
        const x = Math.sin(angle) * radius
        const z = Math.cos(angle) * radius

        gltf.scene.scale.set(0.1, 0.1, 0.1)
        gltf.scene.position.set(x, 0.0, z)
        gltf.scene.rotation.y = Math.random() * 145
        gltf.scene.traverse( function (node){
            if (node.isMesh) {node.castShadow = true}
        })
        scene.add(gltf.scene)
    }
)
/*
====================================== END GEO ========================================================
*/

/*
====================================== START LIGHTS ========================================================
 */

/*
====================================== POINT LIGHT ===========================================================
 */
const pointLight1 = new THREE.PointLight('#ff00e4', 1.25)
pointLight1.position.x = 3
pointLight1.position.y = 2
pointLight1.position.z = 1
scene.add(pointLight1)

const pointLight2 = new THREE.PointLight('#00ffff', 1.25)
pointLight2.position.x = -3
pointLight2.position.y = 2
pointLight2.position.z = 1
scene.add(pointLight2)

const pointLight3 = new THREE.PointLight('#00ffff', 3.0)
pointLight3.position.x = 0
pointLight3.position.y = .3
pointLight3.position.z = 0
scene.add(pointLight3)
/*
====================================== Hemispherical Light ===========================================================
 */
const hemisphericalLight = new THREE.HemisphereLight('#ffffff', '#555555', 1.0)
scene.add(hemisphericalLight)

/*
====================================== SPOT LIGHT ===========================================================
 */
const spotLight1 = new THREE.SpotLight('#00ffff', 4.0, 5, Math.PI * 1.4)
spotLight1.castShadow = true
spotLight1.shadow.mapSize.width   = 1024
spotLight1.shadow.mapSize.height   = 1024
spotLight1.shadow.camera.near = .2
spotLight1.shadow.camera.far = 200
//spotLight1.shadow.bias = 0.0005
spotLight1.position.set(2.0, 3.0, 3.0)
spotLight1.lookAt(new THREE.Vector3())

scene.add(spotLight1)
scene.add(spotLight1.target)

const spotLight1Helper = new THREE.CameraHelper(spotLight1.shadow.camera)
spotLight1Helper.visible = false
scene.add(spotLight1Helper)
/*
====================================== END SPOT LIGHT ===========================================================
 */

/*
====================================== AREA LIGHT ===============================================================
 */
const rectAreaLight = new THREE.RectAreaLight('#ffffff', 20, 5, 1)
rectAreaLight.position.set(0.0, 5.0, 2.0)
rectAreaLight.lookAt(new THREE.Vector3())
scene.add(rectAreaLight)

/*
====================================== BUILD RAYCASTER ========================================================


const raycaster = new THREE.Raycaster()
const rayOrgin = new THREE.Vector3(0, 0, 10)
const rayDirection = new THREE.Vector3(0, 0, 0)
rayDirection.normalize()

raycaster.set(rayOrgin, rayDirection)
const intersect = raycaster.intersectObject(sphere1)
console.log(intersect)
 */

/*
====================================== START SCENE ========================================================
 */

/*
====================================== SCREEN SETTINGS ========================================================
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/*
====================================== START CAMERA ========================================================
 */
const camera = new THREE.PerspectiveCamera( 75, sizes.width / sizes.height)
camera.position.y =1.5
camera.position.z = 3
camera.near = .0001
scene.add(camera)

/*
====================================== END CAMERA ========================================================
 */

//Create Orbit Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
//Create a Look At
//camera.lookAt(mesh.position)
//Render
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
//This helps set different pixel ratios
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setClearColor('#213776')

/*
====================================== TIME ========================================================
*/

const clock = new THREE.Clock()
let previousTime = 0
const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
    // Model animation
    if(mixer)
    {
        mixer.update(deltaTime)
    }
    // Update controls
    controls.update()
    // Render
    renderer.render(scene, camera)
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}
tick()
